.single-info-header {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-gap: 1em;
  //height: $user-info-header-height;
  z-index: 2;
  background-color: white;

  &__right-container {
    display: flex;
    flex-direction: column;
    gap: 0.2em;

    > p {
      margin: 0;
    }

    &__welcome-label {
      font-size: 1rem;
      color: #54585A;
    }

    &__user-name {
      font-size: 1rem;
      font-weight: 600;
      color: #54585A;
    }

    &__completion-label {
      font-size: 0.875rem;
      color: #999999;
    }

    &__questions-helper {
      color: $helperText;
      font-size: 12px;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
    }
  }
}