/* slideshow
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.slideshow {
  overflow:hidden;
  position:relative;
  .slide-container {
    position: relative;
    left:0%;
    transition: left .8s, height .8s;
    white-space: nowrap;
    display:flex;
    .slide {
      display:flex;
      flex-direction:column;
      justify-content:center;
      width:100%;
      flex-basis:100%;
      flex-shrink: 0;
      border:0;
      padding:0;
      margin:0;
      white-space: normal;
    }
  }
  .img-slide {
    padding-bottom:50%;
  }
}
