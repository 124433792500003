@import "../../src/scss/variables";
@import "../../src/scss/utilities";

.dashboard-user-profile {
  h3 {
    color: $blue;
    font-weight: 700;
    font-size: 25px;
    margin: 0;
  }
  h4 {
    color: $aqua;
    font-weight: 400;
    margin: 0 0 6px;
    font-size: 18px;
  }
  .tags-container {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    .tag {
      height: 40px;
      width: auto;
      margin: 0;
    }
  }

  .info-category {
    display: flex;
    gap: 20px;
    .image-carousel {
      min-width: 40vw;
      .image-contain {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      .slide {
        background-position: center;
        background-size: cover;
        .thumbnail {
          padding-bottom: 80%;
        }
      }
    }
  }
  .info-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 10px 0;
    gap: 15px;
    h3 {
      grid-column-end: span 3;
    }
    &.basic {
      grid-template-columns: repeat(2, 1fr);
      h3 {
        grid-column-end: span 2;
      }
    }
    width: 100%;
    .profile-field {
      padding: 0.25rem;
      min-height: 50px;

      &.filter {
        display: none;
      }
      &.textarea,
      &:only-child {
        grid-column: 1 / 4;
      }
      &.attributes {
        grid-column-end: span 3;
        padding: 5px;
      }
      // &.attractiveness {
      //   grid-row-end: span 1;
      // }
    }

    .note-input {
      resize: none;
      height: 100px;
    }

    .edit-input {
      padding: 20px;
      background: rgba(255, 255, 255, 0.4);
    }

    .edit-trigger {
      border-radius: 5px;
      width: 30px;
      padding: 0px;
      height: 30px;
      display: grid;
      place-content: center;
    }

    .notes {
      background-color: color-mix(in srgb, $teal 50%, white);
      padding: 25px;
      border-radius: 15px;
      grid-column: span 3;
      width: 100%;
      max-height: 400px;
      overflow-x: auto;

      .note {
        color: color-mix(in srgb, $teal 70%, black);
        * {
          margin: 0;
        }
        padding: 5px;
        font-size: 16px;
        div {
          font-size: 14px;
          text-align: right;
        }

        &:not(:last-child) {
          margin-bottom: 10px;
          border-bottom: 1px solid $teal;
        }
      }
    }
  }
  .range .range {
    display: flex;
    align-items: center;
    label {
      color: #aaa;
      font-size: 0.9em;
      flex-grow: 0;
      flex-shrink: 1;
    }
    .range-values {
      margin: 10px;
      display: flex;
      flex-shrink: 0;
      border: 1px solid $teal;
      border-radius: 20px;
      overflow: hidden;
      align-self: center;
      .range-value {
        color: #aaa;
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.9em;
        &:last-child {
          box-sizing: content-box;
          padding-right: 5px;
        }
        &:first-child {
          box-sizing: content-box;
          padding-left: 5px;
        }
        &.active {
          background: $teal;
          color: white;
          font-size: 1.2em;
          font-weight: bold;
        }
      }
    }
  }

  &,
  & * {
    box-sizing: border-box;
  }

  .matchmaker-provided-info {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 40px;

    .matchmaker-ratings {
      display: grid;
      grid-template-columns: min-content auto;
      .rating-label {
        display: flex;
        align-items: center;
        // justify-content: center;
      }
      .rating-display {
        height: 30px;
        background: #eee;
        width: calc(100% - 20px);
        position: relative;
        margin: 10px;
        border-radius: 5px;
        .yes,
        .no {
          height: 100%;
          transition: width 1s;
        }
        .yes {
          position: absolute;
          left: 50%;
          background: $yellow;
          top: 0;
          height: 30px;
          border-radius: 0 5px 5px 0;
        }
        .no {
          position: absolute;
          right: 50%;
          top: 0;
          background: $teal;
          border-radius: 5px 0 0 5px;
        }
      }
    }

    .matchmaker-notes {
      margin: 0 20px;
      max-height: 400px;
      overflow: scroll;

      .date {
        display: block;
        font-size: 0.8em;
        color: #777;
      }
    }
  }

  .mm-reviews {
    h3 {
      .btn {
        color: white;
        font-size: 0.5em;
        margin-left: 30px;
      }
    }
  }

  .muted {
    color: #aaa;
  }
  .member-description-form {
    .attribute-box {
      display: flex;
      align-items: center;
      h4 {
        width: 200px;
        margin-bottom: 0;
      }
    }
    .comment {
      display: flex;
      margin-top: 12px;
      h4 {
        width: 200px;
      }
      textarea {
        width: 100%;
        max-width: 500px;
        height: 6em;
        margin-bottom: 20px;
        padding: 10px;
        resize: none;
      }
    }
  }
}

.member-description-form {
  margin: 20px;
  .wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 20px 0;
  }
  .attributes {
    display: grid;
    grid-template-columns: 100%;
    padding-right: 40px;
  }
  .attribute-box {
    display: grid;
    grid-template-columns: auto 30% 30%;
    border-bottom: 1px solid #ddd;
    margin: 0 10px;
    h4 {
      display: flex;
      align-items: center;
      // padding-left: 20px;
    }
  }
  textarea {
    width: 100%;
    height: 100%;
    resize: none;
  }
}

.rating-widget {
  position: relative;
  p a {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .rating-tooltip {
    position: absolute;
    height: auto;
    left: 0;
    top: 100%;
  }
  p a {
    margin-top: 0px;
    margin-bottom: 0px;
    color: $red;
    cursor: pointer;
  }
}

@include for-mobile {
  .dashboard-user-profile {
    h3 {
      width: 100%;
    }
    h4 {
      font-size: 15px;
    }
    .info-category {
      flex-wrap: wrap;
    }
    .profile-field {
      font-size: 14px;
    }
    .info-container {
      display: flex;
      flex-wrap: wrap;
    }
    .image-carousel {
      width: 100%;
    }

    .range .range {
      label {
        font-size: 0.8em;
      }
    }
  }
}
