@import "../../scss/variables.scss";

.datespot-filter {
  padding-bottom: 5px;
  margin-bottom: 10px;
  .filter-label {
    color: $teal;
    padding: 5px 0 10px;
    font-weight: bold;
    text-transform: capitalize;
    display: flex;

    &-text {
      margin-right: 0.5rem;
    }

    &-question-icon {
      color: rgba(42, 45, 46, 0.8);
      text-transform: none;
      font-weight: normal;
      width: 24px;
      height: 24px;
      padding-right: 8px;
    }

    &-popOver-tooltip {
      @include for-tablet-down {
        transform: translateX(-100%) translateY(1rem) !important;
      }
    }
  }

  .checkboxes {
    .help-text {
      margin-top: -5px;
      margin-bottom: 9px;
      font-size: 0.75em;
      color: #888;
    }

    .options {
      &:not(.range) {
        display: grid;
        grid-template-columns: repeat(4, 25%);
        gap: 10px;
        @include for-mobile {
          grid-template-columns: none;
        }
        &.half-grid {
          display: grid;
          grid-template-columns: repeat(2, 50%);
        }
      }

      &.range {
        display: flex;
        align-items: center;
        @include for-mobile {
          flex-direction: column;
          gap: 10px;
        }
      }

      // grid-auto-columns: minmax(100px, auto);
      // align-items: flex-start;
      // flex-wrap: wrap;
      width: 100%;
      &.range {
        .range-label {
          margin: 10px;
          color: #888;
        }
      }
      // label {
      //   width: 250px;
      // }
    }
  }
  .checkbox {
    display: inline-flex;
    align-items: center;
    margin-right: 30px;
    span {
      margin-right: 0;
      padding-right: 5px;
    }
  }

  .radio-inputs {
    label {
      display: inline-flex;
      align-items: center;
      margin-right: 30px;
      span {
        margin-right: 0;
        padding-right: 5px;
      }
    }
  }

  label {
    cursor: pointer;
    padding-right: 10px;
    &:hover {
      background: rgba(0, 0, 0, 0.02);
    }
  }

  .input-range {
    padding: 10px 0;
    width: 100%;
    max-width: 400px;
    &__label {
      color: rgba(0, 0, 0, 0.7);
    }
    &__track--background {
      height: 10px;
      border-radius: 16px;
      background-color: rgba(84, 88, 90, 0.2);
      top: 15%;
    }
    &__track--active {
      height: 10px;
      background-color: $blue;
    }
    &__slider {
      background-color: $blue;
      border: 1px solid $blue;
      height: 20px;
      top: -5px;
      width: 20px;
    }
    &__label-container {
      display: none;
    }
  }

  .range-inputs {
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    margin: 5px 0 20px;
    input {
      display: block;
      flex: 1;
      margin: 0;
      max-width: 20%;
    }
  }
}

.filter-category {
  border-bottom: 3px solid $teal;
  margin-bottom: 3em;
  &:last-of-type {
    border-bottom: 0;
  }
  .datespot-filter {
    border-bottom: 1px solid #ddd;
    &:last-of-type {
      border-bottom: 0;
    }
  }
  h3 {
    margin: 10px 0;
  }
  // label {
  //   font-size: 18px;
  // }
  input {
    margin-right: 20px;
    background-color: #fff;
    border: 1px solid rgba(84, 88, 90, 0.2);
    border-radius: 5px;
    font-weight: 500;
    height: 38px;
    padding: 0 12px;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    font-size: 18px;
  }
}

.current-filters {
  flex-wrap: wrap;
  font-size: 0.9em;
  max-height: 180px;
  overflow-y: auto;
  margin-bottom: 16px;
  .current-filter {
    margin-right: 15px;
    width: 90%;
    label {
      font-size: 0.9em;
      color: #777;
      position: relative;
      left: 12px;
      top: 5px;
    }
    .filter-box {
      min-width: 100%;
      cursor: pointer;
      margin: 10px;
      border: 1px solid $teal;
      border-radius: 6px;
      box-shadow: 0 0 10px 0 rgba(84, 88, 90, 0.2);
      padding: 3px 0px;
      padding-left: 10px;
      position: relative;
      color: $teal;
      display: flex;
      align-items: center;
      .filter-value {
        padding: 5px;
      }
      .icon-arrow,
      .icon-close-button {
        font-size: 0.9em;
        margin: 5px;
      }
      .icon-arrow {
        display: block;
        margin: 0 0 0 6px;
        transform: rotate(90deg);
        margin-left: auto;
      }
      .icon-close-button {
        border-left: 1px solid #ddd;
        padding: 5px;
      }
    }
  }
}

.filters-slider {
  .slide-content {
    width: 100%;
  }
}

.filter-modal {
  &.regions {
    min-height: 250px;
  }
}
