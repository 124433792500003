$highlight: #25a;
$secondary: #000;

.root-wrapper.is-admin #live-docs {
  max-height: calc(100vh - #{$admin_bar_height});
  margin-top: 0;
  .sidebar {
    max-height: calc(100vh - #{$admin_bar_height});
  }
}
#live-docs {
  h1, h2, h3, h4, h5 {
    font-family: 'Open Sans', sans-serif;
  }

  h1, h2 {
    text-transform: capitalize;
    color: $highlight;
  }
  color: #555;
  margin-top: 0;
  display: flex;
  height: calc(100vh - #{$header-height});

  // category menu
  .sidebar {
    width: 350px;
    max-height: 100%;
    overflow: auto;
    h2 {
      color: white;
      background: $highlight;
      font-size: 20px;
      cursor: pointer;
      text-transform: capitalize;
      border-bottom: 1px solid #ddd;
      padding: 10px 20px;
      margin: 0;
      border-left: 0px solid #333;
      transition: all .3s;
      &.active {
        border-left: 5px solid #fff;
      }
    }
    .cat-item-list {
      h4 {
        font-weight: normal;
        background: #ccc;
        margin: 0;
        padding: 5px;
        padding-left: 40px;
      }
      .item-link {
        padding: 3px;
        padding-left: 55px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 10px;
        }
        &:first-child {
          margin-top: 5px;
        }
      }
    }
    border-right: 1px solid #ddd;
  }

  // main document container
  .docs {
    flex: 1;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .docs-text {
      margin: 0;
      padding: 0;
      margin-top: 15px;
      pre {
        background: #fafafa;
        padding: 10px;
      }
      p > code {
        border: 1px solid #ccc;
        padding: 2px 3px;
        border-radius: 3px;
        background: #fafafa;
      }
    }

    .category-heading {
      text-transform: uppercase;
      margin: 0;
      padding: 15px;
      border-bottom: 1px solid #ddd;
      color: #555;
      display: flex;
      justify-content: space-between;

      input {
        border-radius: 3px;
        outline: 0;
        border: 1px solid #aaa;
        font-size: .7em;
        padding: 0 10px;
      }
    }

    .overview-text {
      margin: 10px 0 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ddd;
    }

    .elements {
      padding: 30px;
      flex: 1;
      overflow-y: auto;

      .type-heading {
        margin: 0 0 10px;
        font-size: 1.2em;
        border-bottom: 1px solid #ddd;
        padding: 10px 0;
      }

      .element {
        border-radius: 3px;
        box-shadow: 1px 1px 5px 3px rgba(0,0,0,.05);
        padding: 15px;
        margin-bottom: 20px;

        .element-heading {
          font-size: 1.2em;
          margin: 0;
          color: $secondary;
          display: flex;
          align-items: center;
          .name {
            font-weight: bold;
          }
          .loc {
            font-size: .8em;
            font-weight: normal;
            display: inline-block;
            padding-left: 10px;
            border-left: 1px solid #ddd;
            margin-left: 10px;
          }
          padding-bottom: 10px;
          border-bottom: 1px solid #ddd;

          .categories {
            margin-left: auto;
            display: flex;
            font-size: .7em;
            .category {
              background: $highlight;
              color: white;
              padding: 3px;
              border-radius: 3px;
              margin: 3px;
            }
          }
        }
      }

      .backticks {
        display: inline-block;
        background: #eee;
        border: 1px solid #ccc;
        padding: 0 5px;
        border-radius: 3px;
        color: #333;
        vertical-align: top;

        pre {
          margin: 0;
          padding: 0;
        }
      }

      .detail-group {
        margin-top: 10px;
        background: #fafafa;
        h4 {
          font-size: 1em;
          padding: 10px;
          border-bottom: 1px solid #ddd;
        }
        ul, p {
          padding: 10px;
          margin: 0;
          padding-top: 0;
          list-style: none;
          padding-left: 20px;
        }
      }
    }
  }
}
