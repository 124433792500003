.drop-down-menu {
  position: absolute;
  right: 0;
  z-index: 100;
  background: #fefefe;
  opacity: 0;
  transition: opacity .3s, bottom .3s, top .3s;
  pointer-events: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, .1);


  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  &.up {
    bottom: 0px;
    &:after {
      z-index: -1;
      content: "";
      position: absolute;
      bottom: -19px;
      right: 5px;
      border: 10px solid transparent;
      border-top: 10px solid #fefefe;
    }
    &.active {
      bottom: 30px;
    }
  }
  &.down {
    top: 0px;
    &:after {
      z-index: -1;
      content: "";
      position: absolute;
      top: -19px;
      right: 5px;
      border: 10px solid transparent;
      border-bottom: 10px solid #fefefe;
    }
    &.active {
      top: 20px;
    }
  }
}
