.launchpad-custom-page {
  .section-edit {
    padding-top: 28px;
    transition: padding .3s;
    position: relative;
    .toggle-section {
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      z-index: 2;
      height: 22px;
      display: flex;
      justify-content: space-between;
      button {
        font-size: 14px;
        opacity: .5;
        &:hover {
          opacity: 1;
        }
      }
    }

    &.shown {
      padding-top: 0px;
      .toggle-section {
        height: 0px;
      }
    }
  }

  .add-bar {
    .add-button {
      text-align: center;
      background: rgba(0,0,0,.1);
      opacity: .2;
      padding: 15px;
      cursor: pointer;
      transition: opacity .3s;
    }
    &:hover {
      .add-button {
        opacity: 1;
      }
    }
    .add-layout {
      display: flex;
      >div {
        cursor: pointer;
        flex: 1;
        padding: 15px;
        text-align: center;
        background-color: rgba(0,0,0,0);
        transition: background-color .3s;
        &:not(:last-child) {
          border-right: 1px solid #ddd;
        }
        &:hover {
          background-color: rgba(0,0,0,.1);
        }
      }
    }
    border-bottom: 1px solid #ddd;
  }
}
