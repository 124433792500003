@import "./variables";
@import "./utilities";

/*
----------------
global.scss
----------------
This is for styles which are to be applied globally. That means things like
global typography and layouts, etc.

*/


.root-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  main {
    flex: 1;
    &.padded {
      padding-top: 60px;
    }
  }

  &.is-admin {
    min-height: calc(100vh - #{$admin_bar_height});
    main {
      margin-top: $header_height;
    }
  }
}

.text-alert-red {
  color: $red;
}


.container {
  max-width: 1000px;
  margin: 0 auto;
}

@media (min-width: 992px) {
  main,
  .home-page-container__content {
    width: 100vw;
  }
}

.root-wrapper {
  width: 100%;
  position: fixed;
}

main,
.home-page-container__content {
  max-width: 100%;
}

.home-page-container__content {
  .match-makers-page-content {
    .section-six {
      background-size: cover;
    }
  }
}

.alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn-item:hover {
  color: transparent !important;
}

.user-info-modal .modal .tool-tip-component {
  width: 400px;
  .icon-close-button {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
}

.login-container {
  .connection-response {
    padding: 0 50px 17px;
    text-align: center;
    > .flex-column {
      width: 100%;
    }
    h2 {
      margin-bottom: 0;
    }
  }
}

/* snips
–––––––––––––––––––––––––––––––––––––––––––––––––– */

// visual fix for odd nesting as a symptom of execCommand()
h1, h2, h3, h4, h5, h6 {
  .snip p {
    padding: 0px;
    margin: 0px;
  }
}

.snip-container {
  &:not(:focus).empty {
    span {
      // width: auto !important;
      &:not([data-slate-zero-width="n"]) {
        // display: block;
      }
    }
  }
  &.empty {
  }
  position:relative;
  border: 1px dashed transparent;
  &.admin {
    &:hover, &:focus {
      outline: 0;
      background: rgba(0,0,0,.05);
      border: 1px dashed #ddd;
    }
    .overlay {
      background: rgba(0,0,0,.05);
      border: 1px dashed #ddd;
      position: absolute;
      top: -10px;
      left: -10px;
      bottom: -10px;
      right: -10px;
    }
  }

  p {
    color: inherit;
  }

  blockquote {
    display: block;
    padding: 20px;
    margin: 30px;
    border-left: 4px solid $yellow;
    color: $teal;
    font-size: 1.4em;
  }

  &.inline {
    p {
      padding: 0;
      margin: 0;
    }
  }

  img {
    display: block;
    width: 100%;
  }

  ul {
    padding-left: 40px;
    li {
      list-style: disc;
      margin-bottom: .5em;
    }
  }
  ol {
    padding-left: 40px;
    li {
      list-style: decimal;
      margin-bottom: .5em;
    }
  }
}

.gap-05 {
  gap: 0.5rem;
}
