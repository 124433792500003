@import "../../../../scss/variables";
@import "../../../../scss/utilities";

#matchmaker-dashboard {
  .sidebar {
    display: flex;
    flex-direction: column;
    padding: 10px;
    .active {
      background: #013b4d;
      * {
        color: white !important;
      }
    }
    .filter-button {
      padding: 16px;
      display: flex;
      justify-content: center;
    }
    button {
      background: #eee;
      border: 0;
      padding: 20px 10px;
      margin: 3px 0;
      display: flex;
      font-size: 14px;
      .fa {
        width: 25px;
        font-size: 20px;
      }
      .text {
        text-align: left;
      }
      &:focus {
        outline: none;
      }

      .text {
        flex: 1;
        justify-content: center;
        margin: 0 10px;
      }
    }
    .saved-filters {
      h4 {
        text-align: center;
        margin-top: 40px;
      }
    }
    .save-filters {
      margin-top: auto;
      button {
        width: 100%;
        background: $teal;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 0.85em;
      }
    }
  }
  .dashboard-container {
    display: flex;
    flex-direction: column;
  }
  .tools {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    .search-box {
      input {
        padding: 10px;
      }
    }
    .filters {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .filter-buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 10px;
    }
  }
  .filter-btn {
    background-color: #ffd758;
    color: #54585a;
    box-shadow: none;
    margin-top: 0em;
    padding: 0 40px;
    width: 220px;
    height: 2.5em;
    min-width: 145px;
    outline: none;
    border: 2px solid $yellow;
    z-index: 3;
    font-size: 14px;
    font-weight: 800;
    min-height: 44px;
  }
  .clear-filters {
    background-color: #eee;
    color: $textPrimary;
    box-shadow: none;
    margin-top: 10px;
    margin-left: -7px;
    padding: 0 30px;
    width: 220px;
    height: 2.5em;
    min-width: 145px;
    outline: none;
    border: 2px solid #eee;
    z-index: 3;
    font-size: 14px;
    font-weight: 800;
    min-height: 44px;
  }

  .tab-container {
    .tab-content {
      display: none;
      &.active {
        display: block;
      }
    }
  }
  .tab-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-right: 10px;
  }
  .tab-nav {
    display: flex;
    border-radius: 10px;
    padding: 4px;
    background: #eeeeef;
    width: 100%;
    a {
      padding: 10px 20px;
      background: transparent;
      border-radius: 10px;
      flex: 1;
      text-align: center;
      &.active {
        background: $teal;
        color: $ecolor1;
        font-weight: bold;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .filter-info {
    margin: auto;
    padding: 0 1em;
    font-size: 0.75em;
    color: #888;
    @include for-mobile {
      font-size: 10px;
    }
  }
  .filters-slider {
    .slide-container {
      align-items: flex-start;
      .slide {
        padding: 20px;
        align-items: flex-start;
      }
    }
  }
}

.save-filter-form {
  display: flex;
  input {
    flex: 1 !important;
    padding: 10px !important;
  }
  button {
    padding: 10px 30px !important;
    width: fit-content !important;
    margin: 0 !important;
  }
}
