@import "../../scss/variables";

.main-header {
  padding: 0 26px !important;
  transition: padding 0s !important;
  box-shadow: 0 2px 5px -2px rgba(0,0,0,.05);
  top: 0;
  height: $header_height;
  width: auto;

  .date-spot-logo {
    //margin-right: auto;
  }
  .header-menu-spacer {
    margin-right: 0px;
  }

  .user-menu {
    position: relative;
    padding: 20px 10px;
    //margin-left: 20px;
    cursor: pointer;
    .drop-down-anchor {
      position: relative;
      top: 0px;
    }
    a {
      display: block;
      width: 150px;
      text-align: center;
      padding: 10px;
      color: #333;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        background: $yellow;
      }
      &:not(:last-child){
        border-bottom: 1px solid #ddd;
      }
    }
  }

  .matchmaker-status {
    border-right: 1px solid #eee;
    border-left: 1px solid #eee;
    padding: 10px 20px;
    // margin-right: 20px;
    text-align: center;
    label {
      font-size: .8em;
      color: #aaa;
    }
    .status {
      color: $teal;
      font-weight: 800;
      display: flex;
      align-items: center;
      .icon:before, .fa {
        color: #aaa;
      }
      .icon, .fa {
        margin: 10px;
      }
      .fa {
        font-size: 1.2em;
      }
    }
    a {
      display: inline-block;
      margin: 0;
      line-height: normal;
    }
    .icon-plus-yellow {
      display: inline-block;
      position: relative;
      top: 3px;
    }
  }
}

.is-admin {
  .main-header {
    top: $admin_bar_height;
  }
}
