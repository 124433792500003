@import "../../src/scss/variables";

.users-list {
  // DO NOT REMOVE
  // necessary to prevent weird flex display issues
  min-height: 1px;
  height: 100%;

  display: flex;
  flex-direction: column;

  .table-container {
    overflow: auto;
    box-shadow: inset 0 -3px 3px 0 rgba(0, 0, 0, .05);
    flex: 1;
    .member-count {
      padding: 20px 10px;
      align-self: start;
      font-size: 18px;
      color: #2E383A;
    }
    > table {
      padding-top: 20px;
      overflow: scroll;
      flex: 1;
      width: 100%;
      th {
        background: $teal;
        color: white;
        padding: 10px 10px;
        align-items: center;
        justify-content: center;
        font-weight: 300;
        position: sticky;
        top: 0;
      }
      td {
        text-align: center;
        > .fa-ban {
          color: red;
        }
      }

      td, th {
        padding: 5px;
        &:first-child {
          padding-left: 10px;
        }
        &:last-child {
          padding-right: 10px;
        }
      }
      tr {
        border-bottom: 1px solid #eee;
      }

      tbody {
        max-height: 100px;
        overflow: scroll;
      }
    }
    .empty-table {
      padding: 20px;
      color: #777;
    }
  }

  .indicators {
    .fa-link {
      color: $teal;
      font-size: 1.4em;
    }
    span {
      margin: 5px;
    }
  }
  .user-row {
    position: relative;

    &:hover {
      background: rgba(101, 196, 192, 0.2);
    }

    td.attractiveness {
      color: $teal;
      font-weight: 800;
      font-size: 1.2em;
    }

    td.available {
      min-width: 80px;
      font-size: .9em;
      .available-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        > span {
          display: flex;
          align-items: center;
          &:not(:last-child) {
            padding-bottom: 2px;
            margin-bottom: 2px;
            border-bottom: 1px solid #ddd;
          }
          .fa, .icon {
            width: 20px;
            margin-right: 5px;
            font-size: .9em;
          }
          .icon {

          }
        }

      }
    }

    .actions {
      padding: 20px !important;
      .icon-more {
        cursor: pointer;
      }
    }
    .action-wrapper {
      height: 100%;
    }
    .action-menu {
      .user-profile-action {
        display: flex;
        align-items: center;
        min-width: 150px;
        font-size: .9em;
        padding: 10px;
        &.inactive {
          color: #bbb;
        }
        .content {
          display: inline-block;
          white-space: nowrap;
          margin: 0 5px;
        }
        &:hover {
          background: $yellow;
          cursor: pointer;
        }
        .icon, .fa {
          display: inline-block;
          // padding: 5px;
          font-size: 1em;
          margin-right: 5px;
          width: 20px;
          text-align: center;
        }
      }
    }
  }

  &.matchmaker {
    height: 100%;
    .table-container {
      > table {
        td {
          padding: 10px;

          &:first-child {
            padding-left: 20px;
          }

          &.name {
            text-align: left;
          }
          &.email {
            max-width: 200px;
            overflow: auto;
            word-wrap: break-word;
          }
          &:not(:first-child) {
            border-left: 1px solid #eee;
          }
        }
      }
    }
  }
}
