@import "../../scss/variables";
@import "../../scss/utilities";

.form-input__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position:relative;

  .form-input__root {
    margin-bottom: 0;
    border: 1px solid #c1c7d0;
    border-radius: 0.313rem;
    display: block;
    height: 38px;
    font-size: 1rem;
    line-height: 1.5;
    padding: 0.4rem 0.8rem;
    box-sizing: border-box;

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      font-size: 1rem;
      opacity: .8;
    }

    &.form-input__error-state {
      border-color: $redError;
    }
    
    position:relative;


  }

  &-question-icon{
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 60%;
    transform: translateY(-50%);
    padding-right: 8px;
  }

  &-popOver-tooltip{
    @include for-tablet-down{
      transform: translateX(-100%) translateY(1rem) !important;
    }
  }

  .form-tooltip {
    max-width: 50%;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
    width: 345px;
    background-color: #f2f3f7;
    padding: 1rem .7rem;
    z-index: 10;
    border-radius: 1rem;
    left: 100%;
    top: 0;
    margin-left: 1rem;
    color: rgba(42,45,46,.8);
  }
}