@import "src/scss/variables";

.text-box {
  .text-area {
    background-color: #fff;
    border: 1px solid rgba(84, 88, 90, 0.2);
    border-radius: 4px;
    color: $textPrimary;
    font-weight: 500;
    font-size: 1.125rem;
    outline: none;
    padding: 12px;
    resize: none;
    min-width: 93%;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }

  .helper-text {
    color: $helperText;
    font-weight: 500;
    font-size: 12px;
    text-align: left;
    font-style: italic;
    font-family: "Montserrat", sans-serif;
  }
}