#region-manager {

  .regions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    .region {
      position: relative;
      border: 1px solid #ddd;
      box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, .1);
      padding: 15px;
      .input-row {
        display: flex;
        .Input {
          flex: 1;
        }
        .placeholder {
          width: 25px;
        }
        &.bottom {
          align-items: center;
        }
      }
      .update {
        padding-right: 25px;
        .lat-lng {
          font-size: .8em;
          color: #ccc;
        }
        margin-top: 5px;
        text-align: right;
        button {
          margin-left: auto;
        }
      }
    }
  }
}
