/* modal
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.launchpad-modal {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:rgba(0,0,0,.5);
  z-index:89;
  display:flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity .5s;
  //padding-top: $admin_header_height;

  .modal-container {
    transition: margin-top .5s;
    margin-top: -80px;
    max-height:100%;
    padding: 5% 5% 0;
    overflow: auto;

    .modal-body {
      background:white;
      border-radius:3px;
      margin: 0 auto;
      width:95%;
      max-width:800px;
      padding: 0;

      .modal-header {
        position: relative;
        padding-right: 40px;
        min-height: 40px;
        text-transform: uppercase;
        button {
          position:absolute;
          right:7px;
          top:7px;
          border-radius: 3px;
        }
      }
      .modal-content {
        border: 0;
        padding: 20px;
        padding-bottom: 30px;
      }
    }
  }

  &.closed {
    opacity: 0;
    pointer-events: none;
    .modal-container {
      margin-top: -80vh;
      .modal-content {
        pointer-events: none;
      }
    }
  }
}


/* Confirmation Modal (should be refactored for generic styling)
-------------------------------- */
#modal_confirm {
  text-align:center;
  z-index: 191;
  .confirm {
    margin-top: 30px;
    .modal-content {
      button {
        margin: 5px;
      }
    }
  }
}
