@import "src/scss/utilities.scss";

.footer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 64px 0 16px 0;
  gap: 2rem;

  .footer__logo {
    width: 128px;
    cursor: pointer;
  }

  .footer__social {
    display: flex;
    gap: 1rem;

    .footer__social-icon {
      width: 25px;
      padding: 0.2rem;
      cursor: pointer;
    }
  }

  .footer__links-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6rem;

    .footer__links-column {
      display: flex;
      flex-direction: column;
      gap: .5rem;

      .footer__links-title {
        font-size: 1.25rem;
        font-weight: 600;
        color: #34c7c0;
        text-transform: uppercase;
        padding-bottom: .5rem;
      }

      .footer__link {
        color: rgba(42,45,46,.8);
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.5;
        text-decoration: none;
        text-transform: capitalize;
        margin: 0 20px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .footer__copyright {
    font-size: .875rem;
    color: #8d8d8d;
  }
}

// Mobile
@include for-mobile {
  .footer__links-container {
    grid-gap: 2rem !important;;
    grid-template-columns: 1fr !important;
    grid-template-rows: repeat(3, 1fr);
  }

  .footer__link {
    margin-bottom: 16px !important;
  }

  .footer__links-column {
    gap: 0 !important;
  }

  .footer__links-title {
    padding-bottom: 1rem !important;
    font-size: 1.15rem !important;
  }
}