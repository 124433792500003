/* accordion
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.accordion {
  position:relative;

  .accordion-panel {
    .panel-toggle {
      cursor: pointer;
      background: $acolor1;
      color: $acolor2;
      margin: 0 -15px;
      padding: 15px;
      font-size:1.2em;
    }
    .panel-body {
      max-height: 0px;
      overflow:hidden;
      transition: max-height .3s ease-out;
    }

    &.open {
      .panel-body {
        max-height: 80vh;
        transition: max-height .3s ease-in;
        overflow:auto;
      }
    }
  }

}
