/* Header
–––––––––––––––––––––––––––––––––––––––––––––––––– */
// .is-admin header {
//   top: $admin_bar_height;
// }

// NOTE: applying padding to main content area since header isn't fixed
.is-admin {
  header {
    top: $admin_bar_height;
  }
}



header {
  position: fixed;
  padding: 5px;
  left: 0;
  top: 0;
  align-items: center;
  width: 100%;
  background: $ecolor1;
  //height: $header_height;
  z-index:89;
  min-height: calc(3rem + 4px);
  box-shadow: 0 1px 7px -4px rgba(0,0,0, .3);

  .header-wrapper {
    max-width: 1100px;
    margin: 0 auto;
  }

  .shadow-nav {
    position:absolute;
    top: -1000px;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
  }

  .hamburger {
    display:inline-block;
    width:30px;
    .ham-x span {
      background: $tcolor1;
    }
  }

  .main-menu {
    display:flex;
    align-items: center;
    >* {
      flex: 1;
    }
    .logo-container {
      flex: 0;
    }
    .logo {
      position:relative;
      padding:0;
      margin-top: 3px;
      margin-bottom: 3px;
      .editable-image {
        margin:0;
        width: 200px;
        height: 45px;
        @media (max-width: 500px){
          width: 150px;
          margin-right: 5px;
        }
      }
    }
    .list-menu {
      min-height: 100%;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      display: inline-block;
      padding: 0;
      margin: 0 0 0 1px;
      vertical-align:middle;
      position: relative;
      .sub-menu {
        padding: 10px;
        background: white;
        position: absolute;
        top: 100%;
        min-width: 200px;
        display: none;
        text-align: left;
        z-index: 2;
        box-shadow: 0 0 2px 2px rgba(0,0,0,.1);
        li {
          display: block;
        }
      }
      &:hover {
        .sub-menu {
          display: block;
        }
      }
    }

    .menu {
      // flex: 1;
      // text-align: center;

      li {
        .sub-menu a:not(.btn):not(.logo) {
          line-height: 3rem;
        }

        a {
          i {
            margin-left: 5px;
          }
          img {
            max-width:15px;
            margin-left: 5px;
            transform: translate(0px, 0px);
            transition: transform .5s;
          }
          &:hover {
            img {
              transform: translate(0px, 3px)
            }
          }
          &:not(.btn):not(.logo) {
            margin: 0;
            margin-right: 15px;
            display:block;
            line-height: 3rem;
            transition: border-bottom .5s;
          }
          &.btn {
            margin-left:10px;
          }
          color: $tcolor1;
          font-size: 1rem;
          text-decoration: none;
        }
      }


      .collapsible-nav {
        .menu-link {
          border-top: 1px solid #ddd;
          a {
            display: inline-block;
          }
        }
        ul {

          display: block;
          list-style: none;
          li {
            padding: 0;
          }
          .sub-menu {
            text-align: left;
            position: relative;
            padding-left: 30px;
            top:0;
            left: 0;
            background: transparent;
          }
        }
        &.open {
          height:calc(100vh - #{$header_height});
          max-height: 100vh;
          overflow:scroll;
          padding: 20px;
          padding-bottom: 25vh
        }
        max-height:0px;
        overflow:hidden;
        transition: max-height .3s, padding .3s;
        position:absolute;
        background:white;
        width:100%;
        left:0;
        padding:0;
        li {
          display:block;
        }

        a {
          &:not(.btn):not(.logo) {
            padding: 3px 1em;
            padding-right: 4em;
            margin-left:40px;
          }
          &.btn {
            margin-left: 30px;
          }
        }
      }
    }
  }

  .search-menu {
    flex: 1;
  }

  .tools {
    display: flex;
    padding: 0 10px;
    align-items: center;
    .btn {
      margin: 0 10px;
      margin-left: 20px;
    }
    .icons {
      .fa {
        color: #999;
        font-size: 1.5em;
        margin: 0 10px;
      }
    }
  }
  .header-right {
    min-width: 150px;
    min-height: 65px;
  }

  .sub-nav {
    background: #eee;
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      list-style: none;
      li, .dynamic-link-container {
        font-size: .9em;
        border-right: 2px solid #ccc;
        &:last-of-type {
          border-right: 0;
        }
        a {
          display: block;
          padding: 10px 30px;
        }
      }
    }
  }
}
