/* Animated Icon(s)
======================================= */

.ham-x {
  display:inline-block;
  width:100%;
  padding-bottom: 80%;
  position:relative;
  cursor: pointer;

  .top, .mid, .bottom {
    width: 100%;
    position:absolute;
    display:block;
    padding-bottom: 10%;
    background: white;
    transition: all .5s;
    transform: rotate(0deg)
  }
  .top {
    top:5%;
  }
  .mid {
    top: 50%;
    opacity: 1;
  }
  .bottom {
    top: 95%;
  }

  &.open {
    .top {
      top: 50%;
      transform: rotate(135deg)
    }
    .mid {
      top: 50%;
      transform: rotate(225deg);
      opacity: 0;
    }
    .bottom {
      top: 50%;
      transform: rotate(45deg)
    }
  }
}
