@import "../../scss/variables";

.button__root {
  width: 242px;
  height: 44px;
  font-size: 0.813rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 10rem;
  transition: all 200ms;
  letter-spacing: 1px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5 !important;
    background-color: $greyLightest !important;
    cursor: default;
    pointer-events: none;
  }

  &.--error-state {
    border: 1px solid $redError !important;
  }

  &.button__w-icon {
    justify-content: flex-start;
    gap: 1rem;
    padding-left: 1.5rem;
  }

  &.--primary {
    color: #54585a;
    background-color: #ffd84a;

    &:hover {
      background-color: #f6cb2c;
    }
  }

  &.--facebook {
    background-color: #455da7;
    color: white;

    &:hover {
      background-color: #3b4f8a;
    }
  }

  &.--teal{
    background-color: $teal;
    color: color-mix(in srgb, $teal 40%, black);;

    &:hover {
      background-color: $aqua;
    }
  }

  &.--full-width {
    width: 100%;
  }
}
