@import "../../scss/variables";

.autocomplete {
  display: flex;
  position: relative;

  .autocomplete__popover {
    position: absolute;
    max-height: 200px;
    width: 100%;
    border-radius: 4px;
    background-color: white;
    z-index: 10;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border: 1px solid #ccc;
    overflow: auto;
    top: 100%;
    left: 0;

    .autocomplete__list {
      margin: 0;
      padding: 0;

      .autocomplete__list__option {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
        font-size: 1rem;
        color: $greyDarker;
        font-weight: 400;
        line-height: 1.5;
        border-bottom: 1px solid #f2f3f7;
        list-style-type: none;

        &:hover {
          background-color: #f2f3f7;
        }

        &:last-child {
          border-bottom: none;
        }

        &.active {
          background-color: #f2f3f7;
        }

        .autocomplete__list__option-secondary {
          font-size: 0.8rem;
          color: $greyLight;
          font-weight: 400;
          line-height: 1.5;
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .autocomplete__popover-loading {
    display: flex;
    align-items: center;

    .autocomplete__popover-loading__spinner {
      margin-left: 1rem;
    }
  }

  .autocomplete__no-results {
    padding: 1rem;
    margin: 0;
    font-size: 1rem;
    color: $greyDarker;
    font-weight: 400;
    line-height: 1.5;
    list-style-type: none;
  }
}