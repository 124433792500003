@import "../scss/variables";

.nav-bar__sticky-container {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: white;
  box-sizing: border-box;

  .nav-bar__hamburger-menu__content {
    position: absolute;
    overflow: hidden;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    top: 100%;
    right: 0;
    width: 75%;
    height: 92svh;
    background-color: #013B4D;

    &.open {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      animation: slideInFromRight 0.3s ease-in-out;
    }

    .nav-bar__hamburger-menu__links-container {
      display: flex;
      flex-direction: column;
      top: 0;

      &.unmount {
        animation: slideOutToRight 0.3s ease-in-out;
      }

      .nav-bar__hamburger-menu__nested-item-container {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .nav-bar__hamburger-menu__nested-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid $greyLightest;

          .nav-bar__hamburger-menu__chevron {
            margin-right: 1rem;
            transition: transform 0.2s ease-in-out;

            &.open {
              transform: rotate(180deg);
            }
          }
        }

        .nav-bar__hamburger-menu__nested-links-container {
          display: none;
          align-items: flex-start;
          flex-direction: column;
          border-left: 1px solid $greyLightest;

          &.show {
            display: flex;
          }
        }
      }

      .nav-bar__hamburger-menu__join-button {
        margin-top: 1.7rem;
        width: 90%;
      }
    }
  }
}

.nav-bar__hamburger-menu__item {
  padding: 1.7rem 0 1.7rem 48px;
  margin-top: 8px;
  font-size: 24px !important;
  color: white;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 3px solid #FFDA66;
  cursor: pointer;

  &.borderless {
    border: none;
  }

  @include for-tablet-down {
    text-transform: none;
    font-size: 1.125rem;
    padding: 1rem 0;
  }

  &.nested {
    padding: 1rem 20px;
    border-left: 1px solid $greyLightest;
    margin-left: 48px;
    font-weight: 600;
    box-shadow: none !important;
    transition: all 200ms ease-in-out !important;
    width: 100%;

    &:hover {
      background-color: rgba(52, 199, 192, .75);
      color: white;
    }
  }
}

.nav-bar__container {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  height: 9vh;
  width: 100%;

  .nav-bar__logo {
    width: auto;
    height: 55px;
    object-fit: contain;
    cursor: pointer;
  }

  .nav-bar__items-container {
    display: flex;
    align-items: center;
    gap: 1.75rem;
    height: 100%;

    .nav-bar__dropdown {
      height: 100%;

      .nav-bar__nested-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        cursor: pointer;
        gap: .5rem;

        .nav-bar__chevron {
          font-size: 12px;
          color: #2a2d2e;
          transition: 200ms ease-in-out;

          &.nav-bar__expanded {
            transform: rotate(180deg);
          }
        }
      }

      .nav-bar__popover-menu {
        display: none;
        position: absolute;
        background-color: white;
        z-index: 1;
        border: 1px solid #34c7c0;
        text-align: left;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);

        &.nav-bar__show-popover {
          display: inline-block;

          .nav-bar__item {
            padding: 1rem 20px;
            box-shadow: none !important;
            transition: all 200ms ease-in-out !important;
            font-size: .8rem;

            &:hover {
              background-color: rgba(52, 199, 192, .75);
              color: white;
            }
          }
        }
      }
    }

    .nav-bar__item {
      display: flex;
      align-items: center;
      font-size: clamp(12px, 1vw, .875rem);
      text-transform: uppercase;
      text-decoration: none;
      color: #2a2d2e;
      letter-spacing: 1px;
      transition: box-shadow .2s ease-in-out;
      box-shadow: inset 0 -4px 0 0 transparent;
      height: 100%;
      white-space: nowrap;
      cursor: pointer;
      &.nested {
        font-weight: 600;
        font-size: 12px;
      }

      &:hover {
        box-shadow: inset 0 -4px 0 0 rgba(52, 199, 192, .75);
      }
    }
  }

  .nav-bar__join-button {
    width: 144px;
    margin: 0;
  }

  .nav-bar__hamburger-menu__icon {
    display: none;
    color: rgba(42, 45, 46, .8);
    padding: 18px;
    cursor: pointer;

    &.open {
      color: #34c7c0;
    }
  }
}

@include for-tablet {
  .nav-bar__items-container {
    display: none !important;
  }

  .nav-bar__right-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.75rem;
  }

  .nav-bar__hamburger-menu__icon {
    display: block !important;
  }
}

@include for-mobile {
  .nav-bar__items-container {
    display: none !important;
  }

  .nav-bar__hamburger-menu__icon {
    display: block !important;
  }

  .nav-bar__join-button {
    display: none !important;
  }

  .nav-bar__hamburger-menu__item {

    &.nested {
      padding: 1rem 20px;
      margin-left: 1.2rem;
    }
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOutToTop {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOutToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
