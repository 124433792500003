@import "../../src/scss/variables";

.image-carousel {
  padding: 10px;
  position: relative;

  .selector {
    margin-top: 10px;
    display: flex;
    align-items: center;
    > .fa {
      font-size: 3em;
      cursor: pointer;
    }
    .selector-wrapper {
      flex: 1;
      overflow: hidden;
      margin: 0 10px;
      .selector-container {
        $border-width: 3px;

        position: relative;
        transition: all 0.3s;
        display: flex;
        .select-image {
          cursor: pointer;
          width: 20%;
          flex-shrink: 0;
          padding-bottom: 20%;
          transition: border-color 0.3s;
          border: $border-width solid white;
          &.active {
            border: $border-width solid $teal;
          }
          &:hover {
            border: $border-width solid $yellow;
          }
        }
      }
    }
  }

  .menu-dropdown {
    top: 40px !important;

    .menu-action {
      width: 150px;
      padding: 10px 16px;
      cursor: default;

      &:hover {
        background-color: #65c4c07d;
      }
    }
  }
}
