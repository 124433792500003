@import "../scss/utilities.scss";

.tooltip {
  position: relative;
  z-index: 100;

  &__popover {
    position: absolute;
    top: 0;
    left: calc(50% + 0.5rem);
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    min-width: 200px;
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 4px;
    transform: translateY(calc(-80% - 0.5rem));

    &.open {
      animation: tooltip-open-from-left 200ms ease-in-out forwards;
    }
  }

  &__text {
    font-size: 0.875rem;
  }

  &__popover-left{
    left: calc(-100% - 200px);
  }
}

@keyframes tooltip-open-from-left {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(calc(-100% - 0.5rem));
  }
}
