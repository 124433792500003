@import "../../src/scss/variables";

.title-component {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .title-text {
      color: $teal;
      font-size: 40px;
      font-weight: 300;
      font-family: Montserrat, sans-serif;
      line-height: 49px;
      padding: 0 20px;
      white-space: pre-wrap;
      text-transform: uppercase;
      letter-spacing: -1px;
      @media screen and (max-device-width: 780px) {
          font-size: 30px;
      }
  }
  .blue-line {
      background-color: $teal;
      height: 2px;
      width: 100px;
  }
}

.share-icons {
    z-index: 97;
}

.SocialMediaShareButton--copy {
    position: relative;
    background-color: #43E8D8;
    &:hover {
        cursor: pointer;
    }
    img {
        margin: 2px 0 0 4px;
    }
    #copy-url-to-clipboard {
        display: none;
    }
    #copy-url-to-clipboard-success {
        position: absolute;
        top: 100%;
        right: 0;
        width: auto;
        white-space: nowrap;
        word-break: none;
        opacity: 0;
        transition: opacity .5s;
        padding: 3px 8px;
        color: #fff;
        background-color: #43E8D8;
        &.show {
            opacity: 1;
        }
    }
}
