/* dynamic background
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.is-admin {
  .editable-image {
    &.empty {
      background-color: $acolor2;
    }
    img {
      min-height: 30px;
    }
  }
}
.editable-image {
  position:relative;
  img {
    max-width: 100%;
  }

  &.circle {
    padding-bottom:100%;
    border-radius: 50%;
  }
  .image-tools {
    display:none;
    position:absolute !important;
    bottom: 3px;
    right: 3px;
    z-index:2;
    div, button {
      vertical-align:middle;
      position:relative;
    }
  }
  &.force-editable {
    .image-tools {
      display:block;
    }
  }
  & > * {
    position:relative;
    z-index: 1;
  }
  & > .overlay {
    background:black;
    position:absolute;
    top:0px;
    left:0px;
    height:100%;
    width:100%;
  }
}

.image-getter {
  position:relative;
  .tool-container {
    display: inline-block;
    button {
      border-radius:0;
      font-size:2.5rem;
      background: none;
      color:white;
      background: rgba(0,0,0,.5);
      padding: 7px;
      &:hover {
        background: rgba(0,0,0,.8);
        opacity: 1;
      }
    }
  }
  .tools {
    display:none;
  }
}

.is-admin .image-getter {
  background-color: $acolor2;
  .tools {
    display:block;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    & > button {
      padding:5px 10px;
    }
    .big-plus {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      display:flex;
      flex-direction:column;
      justify-content:center;
      text-align:center;
      color:white;
      font-weight:bold;
      cursor:pointer;
    }
  }
}

// expose image editing controls

.is-admin.edit-images {
  .editable-image {
    &.pointer-all{
      cursor: pointer;
    }

    & > * {
      cursor: auto;
    }
    & > img {
      cursor: pointer;
    }
    .hover-overlay {
      pointer-events: none;
      background: rgba(0,0,0,.05);
      border: 1px dashed #ddd;
    }

    .image-edit {
      position: absolute !important;
      bottom: 10px;
      right: 10px;
      text-align: right;
      background: rgba(255, 255, 255, .5);
      padding: 2px;
      border-radius: 3px;
      padding-top: 10px;
      .image-edit-button, .image-remove-button, .image-configure-button {
        cursor: pointer;
        margin: 0;
        padding: 0 2px;
      }
      .image-remove-button {
        margin-bottom: 2px;
      }
    }
    .image-tools {
      display:block;
    }

  }
}
