@import "../../src/scss/variables";

.user-thumbnail {
  background-size: cover;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-color: #fafafa;
  border: 1px solid #aaa;
  color: #aaa;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.is-admin .dashboard {
  height: calc(100vh - #{$header_height + $admin_bar_height});
  flex-basis: calc(100vh - #{$header_height + $admin_bar_height}) !important;
}

.dashboard {
  height: 90svh;
  padding-bottom: 1rem;
  * {
    box-sizing: border-box;
  }
  overflow: hidden;
  flex-grow: 0 !important;
  flex-shrink: 1 !important;
  background: linear-gradient(
                  315deg,
                  rgba(101, 196, 192, 0.1) 0%,
                  rgba(255, 218, 101, 0.1) 100%
  );
  margin: 0 !important;
  flex: unset !important;

  $panel-padding: 15px;
  .dashboard-panel {
    background: white;
    box-shadow: 0 0 10px 0 rgba(84, 88, 90, 0.2);
    padding: 10px;
    margin-right: $panel-padding;
    margin-top: $panel-padding;
    display: flex;
    flex-direction: column;
  }

  .dashboard-container {
    height: 100%;

    .main {
      overflow: hidden;
      flex: 1;
    }
    .sidebar {
      display: flex;
      flex-direction: column;
    }
    .row {
      display: flex;
      flex: 1;

      &.dashboard-layout {
        height: calc(100% - 5rem);
        padding: 0 1rem;
      }
    }
    .dashboard-column {
      height: 100%;
      display: flex;
      flex-direction: column;
      .dashboard-panel {
        flex: 1;
      }
    }
  }

  .user-row-mobile {
    height: calc(100% - 18vh);
  }
}

.pages-container {
  margin-top: 10px;
}
.pages {
  display: flex;
  justify-content: center;
  // background: #efefef;
  max-width: 100%;
  .page,
  .dots,
  .prev,
  .next {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    &:hover {
      background: #ccc;
    }
  }

  .page-container {
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    div {
      display: inline-flex;
      flex: 1;
    }
    .inner {
      position: relative;
      transition: left 0.3s;
    }
  }

  .prev,
  .next {
    width: auto;
    padding: 0px 15px;
    opacity: 0.3;
    &.active {
      opacity: 1;
    }
  }
  .page.active {
    background: $teal;
    color: white;
  }
  > div {
    cursor: pointer;
  }
}

.dashboard-modal-overlay {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: opacity 0.5s, padding-top 0.5s;
  padding-top: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.open {
    opacity: 1;
    pointer-events: auto;
    padding-top: 0;
  }

  .dashboard-modal-panel {
    background: white;
    border-radius: 3px;
    max-width: 95vw;
    min-width: 80vw;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    margin-top: $nav-bar-height;

    &.referrals {
      width: 90%;
      height: 80%;
    }

    &.edit-credit {
      z-index: 20;
      height: 40%;
    }
  }

  .dashboard-modal-header {
    padding: 20px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-size: 1.3em;
    .fa {
      cursor: pointer;
    }
  }
  .dashboard-modal-body {
    padding: 20px;
    flex: 1;
    overflow: scroll;
  }
}
