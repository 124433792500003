/* auto slider
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.auto-slider {
  position:relative;
  .overlay {
    background: rgba(0,0,0,.4);
    z-index:1;
    position:absolute;
    height: 100%;
    width: 100%;
    left:0;
    top:0;
    display:flex;
    justify-content:center;
    flex-direction:column;

    *:not(.backdrop):not(.overlay):not(.image-tools *) {
      position:relative;
      z-index:1;
    }

    h1 {
      font-size: 6rem;
    }
    h1, h2, h3 {
      text-align:center;
      margin:20px;
      color:white;
      text-shadow: 0 0 4px rgba(0,0,0,.4);
    }
  }

  .next, .previous {
    position:absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 10%;
    min-width:60px;
    max-width:100px;
    cursor:pointer;
    display:flex;
    text-align:center;
    flex-direction: column;
    justify-content: center;
    color:white;
    font-size:40px;
    z-index: 2;

    i {
      display:inline-block;
      position:relative;
      z-index:2;
      opacity: .7;
      transition:all .5s;
    }

    &:hover i {
      opacity: 1;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      height:100%;
      width:100%;
      background: transparent;
      background:linear-gradient(to right, rgba(0, 0, 0,.3), rgba(0, 0, 0, 0));
      opacity: 0;
      transition: opacity .5s;
      content: "";
    }

    &:hover:after {
      opacity: 1
    }
  }
  .next {
    left: auto;
    right: 0;
    &:after{
      background:linear-gradient(to left, rgba(0, 0, 0,.3), rgba(0, 0, 0, 0));
    }
    i {
      padding-right: 5%;
      padding-left: 0px;
    }
    &:hover {
      i {
        padding-left: 5%;
        padding-right: 0px;;
      }
    }
  }

  .previous {
    i {
      padding-left: 5%;
      padding-right: 0px;
    }
    &:hover {
      i {
        padding-right: 5%;
        padding-left: 0px;;
      }
    }
  }

  .buttons {
    position:absolute;
    width:100%;
    bottom:0;
    text-align:center;
    a {
      display:inline-block;
      height:12px;
      width:12px;
      transition:background-color .3s;
      background-color: rgba(255,255,255, .5);
      margin: 10px;
      border-radius:50%;
      cursor:pointer;
      &.active {
        background-color: rgba(255,255,255, 1);
      }
    }
  }

  &.block-animations {
    .slideshow .slide-container {
      transition: none;
    }
  }
}

.triptych {
  overflow:hidden;
  margin-bottom: 20px;
  .slideshow {
    width: 50%;
    margin: 0 auto;
    overflow: visible;
    .img-slide {
      margin:3px;
      padding-bottom: 65%;
    }
  }
  .next, .previous {
    width:25%;
    max-width:25%;
    top: 3px;
    height: calc(100% - 6px);
    i {
      opacity: 0;
    }
    &:hover {
      i {
        opacity: .5;
      }
    }
  }
}
